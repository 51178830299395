<script setup lang="ts">
import IngredientRow from "@/recipes/components/IngredientRow.vue";
import {EditRecipe} from "@/recipes/edit";
import {onMounted} from "vue";
import _ from "lodash";

const props = defineProps<{
  editRecipe: EditRecipe
}>()

onMounted(async () => {
  await props.editRecipe.loadTopTenProductsMatchingQuery()
})
</script>

<template>
  <div class="field">
    <div class="control">
      <input
          class="input"
          placeholder="Search"
          @input="event => {
              const action = _.debounce(async () => { await editRecipe.search(event.target.value) }, 500)
              action()
            }"
      />
    </div>
  </div>
  <div
      class="field is-horizontal"
      v-for="product in editRecipe.state.products"
      :key="product.id"
  >
    <IngredientRow
        :product="product"
        :edit-recipe="editRecipe"
    />
  </div>
</template>

<style scoped lang="sass">

</style>