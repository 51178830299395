<script setup lang="ts">
import { reactive } from "vue";
import { login } from "@/auth/auth";
import router from "@/core/routing";
import { api } from "@/main";

const credentials = reactive({
    username: '',
    password: ''
})

function onLogin() {
    login(credentials, api).then(result => {
        if (result.ok) {
            router.replace('/today')
        }
    })
}
</script>

<template>
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0">
        <a href="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
            <a id="logo" class="text-gray-900" href="/">Yoate</a>
        </a>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                    Sign in to your account
                </h1>
                <form @submit.prevent="onLogin" class="space-y-4 md:space-y-6" action="#">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900">
                            Your username
                        </label>
                        <input name="email" id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            placeholder="name@company.com" required="Email is missing" v-model="credentials.username">
                    </div>
                    <div>
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            required="Password is missing" v-model="credentials.password">
                    </div>
                    <button type="submit" class="w-full text-white bg-lime-600 hover:bg-primary-700 focus:ring-4
                            focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm
                            px-5 py-2.5 text-center">
                        Sign in
                    </button>
                    <p class="text-sm font-light text-gray-500">
                        Don’t have an account yet?
                        <a href="/sign-up" class="font-medium text-lime-600 hover:underline">
                            Sign up
                        </a>
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap')

#logo
    font-family: "Nothing You Could Do", sans-serif
    font-weight: bold
    font-size: 42pt

</style>
