<script setup lang="ts">
import ButtonsGroup, {Button} from "@/components/buttons/ButtonsGroup.vue";

const emit = defineEmits(['onSave', 'onCancel']);

const buttons: Button[] = [
  {id: 'cancel', title: 'Cancel', style: 'plain'},
  {id: 'save', title: 'Save', style: 'primary'},
]

function handleClick(id: string) {
  switch (id) {
    case 'cancel':
      emit('onCancel')
      break
    case 'save':
      emit('onSave')
      break
  }
}
</script>

<template>
  <ButtonsGroup
      :buttons="buttons"
      @click="handleClick"
  />
</template>

<style lang="sass">
</style>