<script setup lang="ts">
import { logout } from "@/auth/auth";
import router from "@/core/routing";
import { api } from "@/main";

defineProps(['routes']);

function logoutAction() {
    logout(api);
    router.replace('/login')
}
</script>

<template>
    <div class="navbar">
        <div class="container">
            <div class="navbar-brand">
                <a id="logo" class="navbar-item" href="/">Yoate</a>
                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="pages">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="pages" class="navbar-menu">
                <div class="navbar-start">
                    <router-link v-for="route in routes" :key="route.path" :to="route.path" class="navbar-item"
                        :class="{ 'router-link-active': $route.path.includes(route.path) }">
                        {{ route.name }}
                    </router-link>
                    <a class="navbar-item" @click="logoutAction()">Logout</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="sass">
@use '@/styles/variables' as *
@use '@/styles/helpers' as *
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap')

.navbar
  background-color: $primary
  border-bottom: rgba($yellow-green, 1) $border-width-wide solid
  color: $text-invert

  .navbar-brand
    font-family: "Nothing You Could Do", sans-serif
    font-weight: bold
    font-size: 27pt
    margin: 0 16pt

    a
      color: $text-invert

  .navbar-menu
    a
      color: $text-invert
      font-size: 11pt
      font-weight: 400
      transition: transition(background)

      &:hover
        background-color: rgba($chamoisee, 0.5)
        color: $text-invert

      &.router-link-active
        background-color: rgba($olivine, 1)
        color: $text-invert
</style>
