<script setup lang="ts">

</script>

<template>
  <div class="page-body">
    <div class="page-wrapper">
      <slot name="page-body"></slot>
    </div>
  </div>
</template>

<style scoped lang="sass">
@use '@/styles/variables' as *

.page-body
  height: 100%
  padding: 0 17%

.page-wrapper
  border-left: rgba($primary, 0.33) $border-width solid
  border-right: rgba($primary, 0.33) $border-width solid
  box-shadow: rgba($primary, 0.03) 0 0 21px
  height: 100%
</style>