import {Result} from "@/core/index";

export class Unauthorized {}

export class API {
    private readonly host: string = '/api'
    private readonly onLogout: (api: API) => void

    constructor(onLogout: (api: API) => void) {
        this.onLogout = onLogout
    }

    get(path: string, params?: any, requiresAuth?: boolean): Promise<Result<any>> {
        let endpoint = `${this.host}/${path}`
        if (params != null) {
            endpoint += `?${params}`
        }
        return this.request(
            endpoint,
            {
                method: 'GET'
            }, requiresAuth);
    }

    post(path: string, body?: any, requiresAuth?: boolean): Promise<Result<any>> {
        return this.request(
            `${this.host}/${path}`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            },
            requiresAuth
        );
    }

    put(path: string, body?: any, requiresAuth?: boolean): Promise<Result<any>> {
        return this.request(
            `${this.host}/${path}`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            },
            requiresAuth
        );
    }

    delete(path: string): Promise<Result<any>> {
        return this.request(
            `${this.host}/${path}`,
            {
                method: 'DELETE'
            });
    }

    request(input: string, init?: RequestInit, requiresAuth?: boolean): Promise<Result<any>> {
        const decorated_init = init || {}
        decorated_init.headers = {...{'content-type': 'application/json'}, ...decorated_init.headers}
        const includeCreds = requiresAuth || true
        if (includeCreds) {
            decorated_init.credentials = 'include'
        }
        return fetch(input, decorated_init).then(response => {
            if (response.ok) {
                return response.json().then(
                    (result) => { return { ok: true, value: result } },
                    (error) => { return { ok: false, error: error } });
            } else if (response.status === 401) {
                this.onLogout(this)
                return { ok: response.ok, error: new Unauthorized() }
            } else {
                return response.json().then(
                    (result) => { return { ok: false, error: result } },
                    (error) => { return { ok: false, error: error } });
            }
        })
    }

}
