import { Product } from "@/products/products";
import { Result } from "@/core";
import { Ingredient, Recipe } from "@/recipes/types";

export type NewRecipeIngredient = {
    product: Product
    amount: number
    action?: string
}

export type NewRecipe = {
    id?: string
    name?: string
    kcal?: number
    prep_time_min?: number
    prep_notes?: string
    ingredients?: NewRecipeIngredient[]
}

export interface EditRecipeGateway {
    fetchProducts(size: number, query?: string): Promise<Result<Product[]>>
    create(newRecipe: NewRecipe): Promise<Result<Recipe>>
    update(newRecipe: NewRecipe): Promise<Result<Recipe>>
}

export enum SaveErrorReason {
    MissingName,
    MissingKcal,
    MissingProducts,
    MissingAmounts
}

export class SaveError extends Error {
    reason: SaveErrorReason

    constructor(reason: SaveErrorReason) {
        super();
        this.reason = reason
    }
}

export class EditRecipe {
    private readonly gateway: EditRecipeGateway
    state: {
        newRecipe: NewRecipe,
        calculateTotalCaloriesFromIngredients: boolean,
        searchQuery?: string,
        products: Product[],
        error?: string
    } = {
            newRecipe: {},
            calculateTotalCaloriesFromIngredients: true,
            products: []
        }

    constructor(gateway: EditRecipeGateway) {
        this.gateway = gateway
    }

    async fetch(
        recipeID: string | undefined,
        find: (recipeID: string) => Recipe | undefined,
        loadByID: (recipeID: string) => Promise<Result<Recipe>>
    ) {
        if (!recipeID || this.state.newRecipe.id === recipeID) {
            return
        }
        const existingRecipe = find(recipeID)
        if (existingRecipe) {
            this.updateFromRecipe(existingRecipe)
            return
        }
        loadByID(recipeID).then((result: Result<Recipe>) => {
            if (result.ok) {
                this.updateFromRecipe(result.value)
                this.state.error = ''
            } else {
                this.state.error = result.error.toString()
            }
        })
    }

    async loadTopTenProductsMatchingQuery() {
        return this.gateway.fetchProducts(10, this.state.searchQuery).then(result => {
            if (result.ok) {
                this.state.products = result.value
            }
        })
    }

    async search(query?: string) {
        this.state.searchQuery = query
        return await this.loadTopTenProductsMatchingQuery()
    }

    updateFromRecipe(recipe: Recipe) {
        const newRecipe: NewRecipe = {}
        Object.assign(newRecipe, recipe)
        newRecipe.ingredients = recipe.ingredients.map((value: Ingredient) => {
            return { product: value, amount: value.amount }
        })
        this.state.newRecipe = newRecipe
    }

    isEditing(): boolean {
        return this.state.newRecipe.id != null
    }

    hasAsIngredient(product: Product): boolean {
        const index = this._indexOfIngredient(product)
        if (index === -1) {
            return false
        }
        return this.state.newRecipe.ingredients?.[index].action !== 'remove'
    }

    updateIngredient(product: Product) {
        if (!this.state.newRecipe.ingredients) {
            this.state.newRecipe.ingredients = []
        }
        const isEditing = this.isEditing()
        const index = this._indexOfIngredient(product)
        if (index !== -1) {
            if (isEditing) {
                if (this.state.newRecipe.ingredients[index].action === 'add') {
                    this.state.newRecipe.ingredients.splice(index, 1)
                } else {
                    const ingredient = this.state.newRecipe.ingredients[index]
                    ingredient.action = 'remove'
                    this.state.newRecipe.ingredients[index] = ingredient
                }
            } else {
                this.state.newRecipe.ingredients?.splice(index, 1)
            }
        } else {
            const action = isEditing ? 'add' as string : undefined
            this.state.newRecipe.ingredients.push({ product: product, amount: 0, action: action })
        }
        this._updateCaloriesIfNeeded()
    }

    amountOfIngredient(product: Product) {
        const index = this._indexOfIngredient(product)
        if (index !== -1 && this.state.newRecipe.ingredients) {
            return this.state.newRecipe.ingredients[index].amount
        }
    }

    updateAmountOfIngredient(product: Product, amount: number) {
        let index = this._indexOfIngredient(product)
        if (index === -1 || !this.state.newRecipe.ingredients) {
            this.updateIngredient(product)
        }
        index = this._indexOfIngredient(product)
        if (index === -1 || !this.state.newRecipe.ingredients) {
            return
        }
        this.state.newRecipe.ingredients[index].amount = amount
        if (this.isEditing() && !this.state.newRecipe.ingredients[index].action) {
            this.state.newRecipe.ingredients[index].action = 'update'
        }
        this._updateCaloriesIfNeeded()
    }

    _indexOfIngredient(product: Product) {
        const products = this.state.newRecipe.ingredients
        if (products == null) {
            return -1
        }
        return products.findIndex(value => value.product.id === product.id)
    }

    _updateCaloriesIfNeeded() {
        if (!this.state.calculateTotalCaloriesFromIngredients) {
            return
        }
        this.state.newRecipe.kcal = this.state.newRecipe.ingredients
            ?.reduce((prev, curr) => prev + curr.product.kcal * curr.amount, 0)
    }

    async save() {
        try {
            await this._save()
            this.state.error = ''
        } catch (e) {
            this.state.error = this._displaySaveError(e as SaveError)
        }
    }

    async _save() {
        const newRecipe = this.state.newRecipe
        if (!newRecipe.name || newRecipe.name.length == 0) {
            throw new SaveError(SaveErrorReason.MissingName)
        }
        if (!newRecipe.kcal || newRecipe.kcal == 0) {
            throw new SaveError(SaveErrorReason.MissingKcal)
        }
        if (!newRecipe.ingredients || newRecipe.ingredients.length == 0) {
            throw new SaveError(SaveErrorReason.MissingProducts)
        }
        const allProductsHasAmounts = newRecipe.ingredients.reduce(
            (prev, curr) => prev && curr.amount > 0,
            true
        )
        if (!allProductsHasAmounts) {
            throw new SaveError(SaveErrorReason.MissingAmounts)
        }
        if (newRecipe.id) {
            await this.gateway.update(newRecipe)
        } else {
            await this.gateway.create(newRecipe)
        }
    }

    _displaySaveError(error: SaveError): string {
        switch (error.reason) {
            case SaveErrorReason.MissingName:
                return "Required field is missing. Specify the name."
            case SaveErrorReason.MissingKcal:
                return "Required field is missing. Specify calories."
            case SaveErrorReason.MissingProducts:
                return "Add at least one product."
            case SaveErrorReason.MissingAmounts:
                return "Each added product should has non-zero amount."
        }
    }

}
