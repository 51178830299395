<script setup lang="ts">
import {onMounted, reactive} from "vue";
import RecipeEntry from "@/recipes/components/RecipeEntry.vue";
import {currentPlan} from "@/main";
import ShoppingList from "@/plan/views/ShoppingList.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import {Intake} from "@/plan/current-plan";
import {Recipe} from "@/recipes/types";
import PlanSidebar from "@/plan/components/PlanSidebar.vue";

const uiState = reactive({
  showShoppingList: false,
  selectedDayIndex: currentPlan.day,
  chosenRecipe: undefined as Recipe | undefined,
  recipePortions: 1,
  showFullRecipe(recipe: Recipe) {
    this.showShoppingList = false
    this.chosenRecipe = recipe
    this.recipePortions = this.selectedDayIntakes()
        .find((value) => value.recipe.id === recipe.id)
        ?.portions ?? 1
  },
  toggleShoppingList() {
    this.showShoppingList = !this.showShoppingList
    this.chosenRecipe = undefined
  },
  calculateTotalKcal() {
    const intake = currentPlan.state.intakes[this.selectedDayIndex]
    return intake.reduce((prev, current) => {
      return prev + current.recipe.kcal || 0
    }, 0)
  },
  selectedDayIntakes(): Intake[] {
    return currentPlan.state.intakes[this.selectedDayIndex]
  }
})

onMounted(() => {
  currentPlan.all();
})
</script>

<template>
  <PageLayout>
    <template v-slot:sidebar>
      <PlanSidebar
          v-model:selected-day-index="uiState.selectedDayIndex"
          :intakes="uiState.selectedDayIntakes()"
          :total-kcal="uiState.calculateTotalKcal()"
          :show-shopping-list="uiState.showShoppingList"
          @on-toggle-shopping-list="() => uiState.toggleShoppingList()"
          @on-select-recipe="(recipe) => uiState.showFullRecipe(recipe)"
      />
    </template>
    <template v-slot:content>
      <div v-if="uiState.chosenRecipe">
        <RecipeEntry
            :recipe="uiState.chosenRecipe"
            :portions="uiState.recipePortions"
        />
      </div>
      <div v-else-if="uiState.showShoppingList">
        <ShoppingList/>
      </div>
    </template>
  </PageLayout>
</template>

<style scoped lang="sass">
</style>