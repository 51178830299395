<script setup lang="ts">
import { formatter } from "@/main";
import AddedIngredientRow from "@/recipes/components/AddedIngredientRow.vue";
import {EditRecipe, NewRecipeIngredient} from "@/recipes/edit";

type Props = {
  editRecipe: EditRecipe
}
const props = defineProps<Props>()

function displayAmount(ingredient: NewRecipeIngredient): string | undefined {
  const amount = ingredient.amount
  if (amount) {
    return `${formatter.format(amount * 100.0)}`
  }
  return undefined
}

function updateAmount(event: InputEvent, ingredient: NewRecipeIngredient) {
  const strValue = event?.target?.value || '0'
  const numValue = parseFloat(strValue) / 100.0
  props.editRecipe.updateAmountOfIngredient(ingredient.product, numValue)
}

function hasIngredients(): boolean {
  return props.editRecipe.state.newRecipe.ingredients?.length > 0
}
</script>

<template>
  <div class="list">
    <div v-if="hasIngredients()">
      <ul>
        <li
            v-for="ing in editRecipe.state.newRecipe.ingredients"
            :key="ing"
        >
          <AddedIngredientRow
              v-if="editRecipe.hasAsIngredient(ing.product)"
              :name="ing.product.name"
              :amount="displayAmount(ing)"
              @update:amount="event => updateAmount(event, ing)"
              @on-delete="() => editRecipe.updateIngredient(ing.product)"
          />
        </li>
      </ul>
    </div>
    <div v-else>
      No added ingredients.
    </div>
  </div>
</template>

<style scoped lang="sass">
</style>
