import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import RecipesList from "@/recipes/views/RecipesList.vue";
import {store as authStore} from "@/auth/auth";
import RecipeDetails from "@/recipes/views/RecipeDetails.vue";
import ProductsList from "@/products/views/ProductsList.vue";
import EditProduct from "@/products/views/EditProduct.vue";
import CurrentDayPlan from "@/plan/views/CurrentDayPlan.vue";
import LoginForm from "@/auth/LoginForm.vue";
import EditPlan from "@/plan/views/EditPlan.vue";
import EditRecipe from "@/recipes/views/EditRecipe.vue";
import SignUp from "@/auth/SignUp.vue";

export const mainRoutes: RouteRecordRaw[] = [
    {path: '/today', component: CurrentDayPlan, name: 'This week', meta: {requiresAuth: true}},
    {path: '/plan', component: EditPlan, name: 'Plan next', props: { nextWeek: true }, meta: {requiresAuth: true}},
    {path: '/recipes', component: RecipesList, name: 'Recipes', meta: {requiresAuth: true}},
    {path: '/products', component: ProductsList, name: 'Products', meta: {requiresAuth: true}},
]

const otherRoutes: RouteRecordRaw[] = [
    {path: '', redirect: '/today'},
    {path: '/', redirect: '/today'},
    {path: '/login', component: LoginForm, name: 'Login', meta: {requiresAuth: false}},
    {path: '/sign-up', component: SignUp, name: 'Sign up', meta: {requiresAuth: false}},
    {path: '/today/edit', component: EditPlan, name: 'Edit Plan', props: { nextWeek: false }, meta: {requiresAuth: true}},
    {path: '/products/new', component: EditProduct, name: 'Add Product', meta: {requiresAuth: true}},
    {
        path: '/products/:product_id/edit',
        component: EditProduct,
        name: 'Edit Product',
        props: true,
        meta: {requiresAuth: true}
    },
    {path: '/recipes/new', component: EditRecipe, name: 'Add Recipe', meta: {requiresAuth: true}},
    {path: '/recipes/:recipe_id', component: RecipeDetails, name: 'Recipe', meta: {requiresAuth: true}},
    {
        path: '/recipes/:recipe_id/edit',
        component: EditRecipe,
        name: 'Edit Recipe',
        props: true,
        meta: {requiresAuth: true}
    },
]

const routes = mainRoutes.concat(otherRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.requiresAuth) {
        next()
    } else if (!authStore.isAuthenticated) {
        next('/login')
    } else if (to.path == '' || to.path == '/') {
        next('/today')
    } else {
        next()
    }
})

export default router
