import {API} from "@/core/api";
import {reactive} from "vue";
import {Result} from "@/core";

const key = 'authorized'

export type Credentials = {
    username: string
    password: string
}

export const store = reactive({
    isAuthenticated: readBoolean(key)
})

export function login(credentials: Credentials, api: API): Promise<Result<any>> {
    return api.post('auth/sign_in', credentials, false).then(result => {
        updateIsAuthenticated(result.ok)
        return result
    })
}

export function signup(credentials: Credentials, api: API): Promise<Result<any>> {
    return api.post('auth/sign_up', credentials, false).then(result => {
        updateIsAuthenticated(result.ok)
        return result
    })
}

export function logout(api: API): void {
    api.delete('auth/sign_out').then(result => {
        updateIsAuthenticated(false)
        return result
    })
}

function updateIsAuthenticated(isAuthenticated: boolean) {
    localStorage.setItem(key, JSON.stringify(isAuthenticated))
    store.isAuthenticated = isAuthenticated
}

function readBoolean(key: string): boolean {
    return JSON.parse(localStorage.getItem(key) || 'false')
}
