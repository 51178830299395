import {Product} from "@/products/products";

export type Recipe = {
    id: string
    name: string
    kcal: number
    prep_time_min: number
    prep_notes: string
    ingredients: Ingredient[]
}

export interface Ingredient extends Product {
    amount: number
}

export function emptyRecipe(): Recipe {
    return { id: '', name: '', kcal: 0, prep_time_min: 0, prep_notes: '', ingredients: [] }
}
