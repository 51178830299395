<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { api, currentPlan } from "@/main";
import router from "@/core/routing";
import WeekdaysMenu from "@/plan/components/WeekdaysMenu.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import ActionsBar from "@/components/ActionsBar.vue";
import SelectRecipeRow from "@/plan/components/SelectRecipeRow.vue";
import { PlannedMeal } from "../edit-plan";
import { editPlan } from "../factories";
import { makeStartDate } from "../misc";

const props = defineProps<{
    nextWeek: boolean
}>();

function nextWeekDate(): Date {
    const date = makeStartDate();
    date.setDate(date.getDate() + 7);
    return date;
}

const model = reactive(props.nextWeek ? editPlan(nextWeekDate(), {}, api) : currentPlan.edit());
const uiState = reactive({
    dayIndex: currentPlan.day,
    intakeIndex: 0,
    showProgress: false,
    mealsAtDay(): PlannedMeal[] {
        if (this.dayIndex + 1 in model.state.mealsByDay) {
            return model.state.mealsByDay[this.dayIndex + 1];
        }
        return [];
    },
    hasSelected(): boolean {
        return this.mealsAtDay().some(meal => meal.position === this.intakeIndex);
    },
    selected(): PlannedMeal | null {
        const meal = this.mealsAtDay().find(meal => meal.position === this.intakeIndex);
        if (!meal) {
            return null;
        }
        return meal;
    },
    isSelected(recipeID: string): boolean {
        if (!this.hasSelected()) {
            return false;
        }
        return this.selected()?.recipe.id === recipeID;
    },
    portionsForRecipe(recipeID: string): number {
        if (this.isSelected(recipeID)) {
            return this.selected()?.portions ?? 0;
        }
        return 0;
    }
})

const intakes = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

async function onSave() {
    uiState.showProgress = true;
    await model.save();
    uiState.showProgress = false;
    if (!props.nextWeek) {
        goHome();
    }
}

function cancel() {
    if (props.nextWeek) {
        model.reset();
    } else {
        goHome();
    }
}

function goHome() {
    router.replace('/');
}

onMounted(async () => {
    uiState.showProgress = true;
    await model.load();
    await model.searchRecipes('');
    uiState.showProgress = false;
});
</script>

<template>
    <PageLayout>
        <template v-slot:sidebar>
            <ActionsBar class="p-3 mt-3" @on-save="onSave" @on-cancel="cancel" />
            <aside class="menu p-4">
                <p class="menu-label">
                    Intakes
                </p>
                <ul class="menu-list">
                    <li v-for="entry in intakes.entries()" :key="entry[0]">
                        <a :class="{ 'is-active': entry[0] === uiState.intakeIndex }"
                            @click="uiState.intakeIndex = entry[0]">
                            {{ entry[1] }}
                        </a>
                    </li>
                </ul>
            </aside>
        </template>
        <template v-slot:content>
            <h1 class="title is-3">
                Editing plan for
                <WeekdaysMenu class="ml-1" :selected-index="uiState.dayIndex"
                    @update:selected-index="(index) => { uiState.dayIndex = index }" />
            </h1>
            <div v-if="uiState.hasSelected()">
                <SelectRecipeRow :recipe="uiState.selected()!.recipe" :portions="uiState.selected()!.portions"
                    :is-selected="true" @on-select="() => { model.remove(uiState.dayIndex + 1, uiState.intakeIndex) }"
                    @update:portions="newValue => { console.log(newValue); }" />
            </div>
            <div v-else>
                <p>No recipe selected</p>
            </div>
            <hr />
            <div v-if="!uiState.showProgress">
                <div class="field is-horizontal" v-for="recipe in model.state.foundRecipes" :key="recipe.id">
                    <SelectRecipeRow :recipe="recipe" :portions="uiState.portionsForRecipe(recipe.id)"
                        :is-selected="uiState.isSelected(recipe.id)" @on-select="(recipeID, isSelected) => {
                            if (isSelected) {
                                model.choose(uiState.dayIndex + 1, uiState.intakeIndex, model.state.foundRecipes.indexOf(recipe), 1);
                            } else {
                                model.remove(uiState.dayIndex + 1, uiState.intakeIndex);
                            }
                        }"
                        @update:portions="(newValue) => { model.state.mealsByDay[uiState.dayIndex + 1][uiState.intakeIndex].portions = newValue; }" />
                </div>
            </div>
            <p v-else>Processing...</p>
        </template>
    </PageLayout>
</template>

<style scoped lang="sass">
@use '@/styles/variables' as *

.page-title
  width: 100%
  padding: 16pt 8pt
  border-bottom: rgba($primary, 0.33) $border-width solid
</style>
