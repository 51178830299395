import {API} from "@/core/api";

export interface Product {
    name: string
    id: string
    kcal: number
    carbs: number
    fats: number
    proteins: number
    category?: string | number
    can_delete: boolean
    can_edit: boolean
}

export function emptyProduct(): Product {
    return {
        id: '',
        name: '',
        kcal: 0,
        carbs: 0,
        fats: 0,
        proteins: 0,
        can_delete: false,
        can_edit: false
    }
}

export type ProductsLoadParams = {
    prev?: boolean
    next?: boolean
    q?: string
    size?: number
}

export class Products {
    private readonly api: API
    readonly state: {
        loaded: boolean
        products: Product[]
        query?: string
        pages: {
            previous?: string
            current?: string
            next?: string
        }
    }

    constructor(api: API) {
        this.api = api
        this.state = {
            loaded: false,
            products: [],
            pages: {}
        }
    }

    load(params: ProductsLoadParams) {
        const params_obj: any = {}
        const cursor = this.formCursor(params)
        if (cursor) {
            params_obj['cursor'] = cursor
        }
        const finalQuery = this.formSearchQuery(params.q)
        if (finalQuery) {
            params_obj['q'] = finalQuery
        }
        params_obj['size'] = params.size || 50
        return this.api
            .get('products', new URLSearchParams(params_obj).toString(), true)
            .then(result => {
                this.state.loaded = true
                if (result.ok) {
                    this.state.products = result.value['items']
                    this.state.pages = {
                        previous: result.value['previous_page'],
                        current: result.value['current_page'],
                        next: result.value['next_page']
                    }
                    this.state.query = finalQuery
                }
            });
    }

    private formCursor(params: ProductsLoadParams) {
        let cursor = null
        if (params.q == null || params.q == this.state.query) {
            cursor = this.state.pages.current
        }
        if (params.prev ?? false) {
            cursor = this.state.pages.previous
        } else if (params.next ?? false) {
            cursor = this.state.pages.next
        }
        return cursor
    }

    private formSearchQuery(q?: string) {
        let finalQuery = this.state.query
        if (q != null) {
            finalQuery = q
        }
        return finalQuery
    }

    loadByID(productID: string) {
        return this.api.get(`products/${productID}`, null, true)
    }

    add(newProduct: Product) {
        const hasName = newProduct.name != null && newProduct.name.length > 0
        const hasKcal = newProduct.kcal != null && newProduct.kcal > 0
        const hasProteins = newProduct.proteins != null
        const hasCarbs = newProduct.carbs != null
        const hasFats = newProduct.fats != null
        if (!(hasName && hasKcal && hasProteins && hasCarbs && hasFats)) {
            return new Promise((value) => value({ status: 'error', reason: 'Missing required fields.' }))
        }
        return this.api.post('products', newProduct, true).then(result => {
            if (result.ok) {
                this.state.products.splice(0, 0, result.value)
                return { status: 'success' }
            } else {
                return { status: 'error', reason: result.error }
            }
        })
    }

    update(item: Product) {
        return this.api.put(`products/${item.id}`, item, true).then(result => {
            if (result.ok) {
                const index = this.state.products.findIndex((value) => value.id === item.id)
                if (index !== -1) {
                    this.state.products[index] = result.value
                } else {
                    this.state.products.splice(0, 0, result.value)
                }
                return { status: 'success' }
            } else {
                return { status: 'error', reason: result.error }
            }
        })
    }

    deleteItem(item: Product) {
        return this.api.delete(`products/${item.id}`).then(result => {
            if (result.ok) {
                const index = this.state.products.indexOf(item)
                this.state.products.splice(index, 1)
            }
            return result
        })
    }
}

export const CATEGORIES = [
    'FRUIT',
    'VEGETABLE',
    'DIARY',
    'MEAT',
    'FISH',
    'BEVERAGE',
    'BAKERY',
    'CANNED',
    'DELI',
    'SPICES',
    'BAKING',
    'FROZEN',
    'SAUCE',
    'SNACKS',
    'GRAINS',
]
