import {createApp, reactive} from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import {API} from "@/core/api";
import {Products} from "@/products/products";
import {Recipes} from "@/recipes/recipes";
import router from "@/core/routing";
import {CurrentPlan} from "@/plan/current-plan";
import {logout} from "@/auth/auth";

createApp(App)
    .use(router)
    .use(VueCookies)
    .mount('#app')

export const api = new API((instance) => {
    logout(instance)
    const _ = router.replace('/login')
})
export const products = reactive(new Products(api))
export const recipes = reactive(new Recipes(api))
export const currentPlan = reactive(new CurrentPlan(api));

export const formatter = new Intl.NumberFormat('en-US');
const kg = 1000.0

export function displayAmount(amount: number) {
  let value = amount * 100
  let suffix = 'g'
  if (value >= kg) {
    value /= kg
    suffix = 'kg'
  }
  return `${formatter.format(value)} ${suffix}`
}
