<script setup lang="ts">

</script>

<template>
  <div class="page-layout">
    <div class="page-sidebar">
      <slot name="sidebar"></slot>
    </div>
    <div class="page-content p-6">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped lang="sass">
@use '@/styles/variables' as *

.page-layout
  padding: 0 10%
  margin: 0
  display: grid
  height: 100%
  grid-template-columns: 200pt auto
  grid-template-rows: auto
  grid-template-areas: "sidebar content"

.page-sidebar
  grid-area: sidebar
  border-right: rgba($primary, 0.33) $border-width solid

.page-content
  grid-area: content
</style>