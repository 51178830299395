<script setup lang="ts">
import RecipeEntry from "@/recipes/components/RecipeEntry.vue";
import {recipes} from "@/main";
import {useRoute} from "vue-router";
import {onMounted, reactive} from "vue";
import {Recipe} from "@/recipes/types";
import {Result} from "@/core";
import CenterPageLayout from "@/components/layouts/CenterPageLayout.vue";
import DefaultButton from "@/components/buttons/DefaultButton.vue";

const route = useRoute()

function getIDFromRoute(): string | undefined {
  return route.params.recipe_id?.toString()
}

function getExistingIfLoaded() {
  const id = getIDFromRoute()
  if (id) {
    return recipes.state.recipes.find((value: Recipe) => value.id === id)
  }
}

const uiState = reactive({
  item: getExistingIfLoaded(),
  fetch() {
    const recipeID = getIDFromRoute()
    if (!recipeID || this.item && this.item.id === recipeID) {
      return
    }
    recipes.loadByID(recipeID).then((result: Result<Recipe>) => {
      if (result.ok) {
        this.item = result.value
      }
    })
  }
})

onMounted( () => {
  uiState.fetch()
})
</script>

<template>
  <CenterPageLayout>
    <template v-slot:page-body>
      <DefaultButton
          class="plain-button"
          title="Close"
          @click="$router.back()"
      />
      <RecipeEntry id="recipe-entry" v-if="uiState.item" :recipe="uiState.item" :portions="1" />
    </template>
  </CenterPageLayout>
</template>

<style scoped lang="sass">
#recipe-entry
  padding: 16pt
</style>