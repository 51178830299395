<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { currentPlan, displayAmount } from "@/main";

const uiState = reactive({
    loading: false,
    purchasedIndexes: []
})

onMounted(async () => {
    uiState.loading = true;
    await currentPlan.shoppingList();
    uiState.loading = false;
})

function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

function formRowKey(category: string, index: number) {
    return `${category}-${index}`;
}
</script>

<template>
    <div id="shopping-list">
        <table class="table is-striped is-hoverable is-fullwidth">
            <tr>
                <th></th>
                <th>Name</th>
                <th>Amount</th>
            </tr>
            <tbody v-for="(value, key) in currentPlan.state.shoppingList" :key="key">
                <tr>
                    <th colspan="2">{{ capitalizeFirstLetter(key) }}</th>
                </tr>
                <tr v-for="entry in value.entries()" :key="formRowKey(key, entry[0])">
                    <td>
                        <input type="checkbox" v-model="uiState.purchasedIndexes" :value="formRowKey(key, entry[0])" />
                    </td>
                    <td :class="{ strike: uiState.purchasedIndexes.includes(formRowKey(key, entry[0])) }">
                        {{ entry[1].name }}
                    </td>
                    <td :class="{ strike: uiState.purchasedIndexes.includes(formRowKey(key, entry[0])) }">
                        {{ displayAmount(entry[1].amount) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="sass">
.strike
    text-decoration: line-through
</style>
