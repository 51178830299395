<script setup lang="ts">
import {currentPlan} from "@/main";
import {onMounted, ref} from "vue";
import DefaultButton from "@/components/buttons/DefaultButton.vue";

defineProps(['selectedIndex'])
const emit = defineEmits(['update:selectedIndex'])

const showDropdown = ref(false)

function selectDay(index: number) {
  hideDropdown()
  emit('update:selectedIndex', index)
}

function toggleDropdown() {
  event.stopPropagation()
  showDropdown.value = !showDropdown.value
}

function hideDropdown() {
  showDropdown.value = false
}

onMounted(() => {
  document.addEventListener("click", function () {
    hideDropdown()
  })
})
</script>

<template>
  <div :class="{ 'is-active': showDropdown, dropdown: true }">
    <div class="dropdown-trigger">
      <DefaultButton
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          class="primary-button"
          :title="currentPlan.days[selectedIndex]"
          @click="toggleDropdown"
      >
        <template v-slot:icon>
          <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M7 10l5 5 5-5"/>
            </g>
          </svg>
        </template>
      </DefaultButton>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
            class="dropdown-item"
            v-for="entry in currentPlan.days.entries()"
            :key="entry[0]"
            :class="{ 'is-active': entry[0] === selectedIndex }"
            @click="selectDay(entry[0])"
        >
          {{ entry[1] }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@use '@/styles/variables' as *

.dropdown
  width: 100%
  .dropdown-trigger
    width: 100%

.tabs ul, .tabs li a
  border-bottom: none

.tabs li:not(.is-active) a:hover
  background-color: rgba($primary, 0.1)
  border-bottom: none

.tabs .is-active
  background-color: rgba($primary, 0.25)
  border-bottom: none
</style>