<script setup>
import {onMounted, reactive} from 'vue'
import {products} from "@/main";
import PageControls from "@/components/PageControls.vue";
import LevelsBar from "@/components/LevelsBar.vue";
import _ from "lodash";
import ProductRow from "@/products/components/ProductRow.vue";
import CenterPageLayout from "@/components/layouts/CenterPageLayout.vue";
import ListActions from "@/components/ListActions.vue";

const uiState = reactive({
  searchQuery: null,
  inProgress: false,
  withProgress: function (f) {
    _.debounce(() => {
      this.inProgress = true
    }, 500)
    f().then(() => {
      this.inProgress = false
    })
  },
  canLoadPrev: function () {
    return products.state.pages.previous != null
  },
  loadPrev: function () {
    this.withProgress(() => products.load({prev: true}))
  },
  canLoadNext: function () {
    return products.state.pages.next != null
  },
  loadNext: function () {
    this.withProgress(() => products.load({next: true}))
  },
  onDeleteProduct: function (product) {
    this.withProgress(() => products.deleteItem(product))
  },
  onSearchInput: function () {
    _.debounce(
        () => {
          this.withProgress(() => products.load({q: this.searchQuery}))
        },
        500
    )()
  },
})

onMounted(() => {
  uiState.withProgress(() => products.load({}))
})
</script>

<template>
  <CenterPageLayout>
    <template v-slot:page-body>
      <div class="page-body-content">
        <h1 class="title is-3">Products</h1>
        <ListActions
            class="block"
            :search-query="uiState.searchQuery"
            @update:search-query="(value) => {
            uiState.searchQuery = value
            uiState.onSearchInput()
          }"
            :can-load-prev="uiState.canLoadPrev()"
            @load-prev="() => uiState.loadPrev()"
            :can-load-next="uiState.canLoadNext()"
            @load-next="() => uiState.loadNext()"
            primary-action="New Product"
            @on-primary-action="() => $router.push('products/new')"
        />
        <progress v-if="uiState.inProgress" class="progress is-small is-primary" max="100"></progress>
        <table class="table is-striped is-hoverable is-fullwidth">
          <colgroup>
            <col span="1" style="width: 33%;">
          </colgroup>
          <tr>
            <th>Title</th>
            <th>Category</th>
            <th>Kcal</th>
            <th>Proteins</th>
            <th>Carbs</th>
            <th>Fats</th>
            <th>Actions</th>
          </tr>
          <ProductRow
              v-for="product in products.state.products"
              :key="product.id"
              :item="product"
              @on-delete="args => uiState.onDeleteProduct(args)"
          />
        </table>
        <LevelsBar>
          <template v-slot:right>
            <PageControls
                class="level-item"
                :can-load-prev="uiState.canLoadPrev()" @load-prev="() => uiState.loadPrev()"
                :can-load-next="uiState.canLoadNext()" @load-next="() => uiState.loadNext()"
            />
          </template>
        </LevelsBar>
      </div>
    </template>
  </CenterPageLayout>
</template>

<style scoped lang="sass">
.page-body-content
  padding: 24pt

  .title
    margin-bottom: 16pt

  .actions
    margin-bottom: 24pt
</style>