<script setup lang="ts">
import ActionsBar from "@/components/ActionsBar.vue";
import router from "@/core/routing";
import { recipes } from "@/main";
import { onMounted, reactive, ref } from "vue";
import SelectIngredients from "@/recipes/components/SelectIngredients.vue";
import AddedIngredients from "@/recipes/components/AddedIngredients.vue";
import { useRoute } from "vue-router";
import LevelsBar from "@/components/LevelsBar.vue";
import CenterPageLayout from "@/components/layouts/CenterPageLayout.vue";

const model = reactive(recipes.edit())
const error = ref('');
const route = useRoute()

function getIDFromRoute(): string | undefined {
    return route.params.recipe_id?.toString()
}

async function fetch() {
    const recipeID = getIDFromRoute();
    console.log(recipeID);
    await model.fetch(recipeID, (id) => recipes.recipeByID(id), (id) => recipes.loadByID(id));
}

async function save() {
    await model.save();
    back();
}

function cancel() {
    error.value = '';
    back();
}

function back() {
    router.back()
}

onMounted(() => {
    fetch();
})
</script>

<template>
    <CenterPageLayout>
        <template v-slot:page-body>
            <div class="page-body-content">
                <LevelsBar>
                    <template v-slot:left>
                        <h1 class="title is-3">{{ model.isEditing() ? 'Edit Recipe' : 'New Recipe' }}</h1>
                    </template>
                    <template v-slot:right>
                        <ActionsBar @on-save="() => save()" @on-cancel="() => cancel()" />
                    </template>
                </LevelsBar>
                <p v-if="error != null" id="error">{{ error }}</p>
                <form>
                    <div class="field">
                        <label class="label">Name*</label>
                        <input class="input" v-model="model.state.newRecipe.name" />
                    </div>
                    <div class="field">
                        <label class="label">Kcal</label>
                        <input class="input" :disabled="model.state.calculateTotalCaloriesFromIngredients"
                            v-model="model.state.newRecipe.kcal" />
                        <label>
                            <input type="checkbox" v-model="model.state.calculateTotalCaloriesFromIngredients" />
                            <span>Calculate from added ingredients</span>
                        </label>
                    </div>
                    <div class="field">
                        <label class="label">Time in minutes</label>
                        <input class="input" v-model="model.state.newRecipe.prep_time_min" />
                    </div>
                    <div class="field">
                        <label class="label">Notes</label>
                        <textarea class="textarea" v-model="model.state.newRecipe.prep_notes" />
                    </div>
                    <div class="two-column-layout">
                        <div class="left">
                            <AddedIngredients :edit-recipe="model" />
                        </div>
                        <div class="right">
                            <SelectIngredients :edit-recipe="model" />
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </CenterPageLayout>
</template>

<style scoped lang="sass">
.page-body-content
  padding: 24pt

.two-column-layout
  display: flex
  gap: 16pt
  flex-wrap: wrap
  margin: 0 auto

  .left, .right
    margin: 0 auto
    flex-basis: 45%
    min-width: 256pt
</style>
