import { Recipe } from "@/recipes/types";
import _ from "lodash";

export type EditPlanState = {
    loaded: boolean;
    mealsByDay: PlannedMealsByDay;
    foundRecipes: Recipe[];
    error?: Error;
};

export type PlannedMeal = {
    recipe: Recipe;
    portions: number;
    position: number;
};

export type PlannedMealsByDay = {
    [day: number]: PlannedMeal[];
};

export interface EditPlanGateway {
    load(startDate: Date): Promise<PlannedMealsByDay>;
    searchRecipes(query: string): Promise<Recipe[]>;
    save(startDate: Date, mealsByDay: PlannedMealsByDay): Promise<void>;
}

export class EditPlan {
    state: EditPlanState = {
        loaded: false,
        mealsByDay: {},
        foundRecipes: [],
    };
    private initialState: PlannedMealsByDay = {};
    private startDate: Date;
    private gateway: EditPlanGateway;

    constructor(startDate: Date, mealsByDay: PlannedMealsByDay, gateway: EditPlanGateway) {
        this.initialState = _.cloneDeep(mealsByDay);
        this.startDate = startDate;
        this.gateway = gateway;
        this.state.mealsByDay = _.cloneDeep(mealsByDay);
    }

    async load() {
        try {
            this.state.mealsByDay = await this.gateway.load(this.startDate);
            this.initialState = _.cloneDeep(this.state.mealsByDay);
            this.state.loaded = true;
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.state.error = e;
            } else {
                console.error(e);
                this.state.error = new Error("Unknown error");
            }
        }
    }

    async searchRecipes(query: string) {
        this.state.foundRecipes = await this.gateway.searchRecipes(query);
    }

    choose(weekday: number, intakeIndex: number, recipeIndex: number, portions: number) {
        const index = this.state.mealsByDay[weekday].findIndex(meal => meal.position === intakeIndex);
        if (index !== -1) {
            this.state.mealsByDay[weekday].splice(index, 1);
        }
        const recipe = this.state.foundRecipes[recipeIndex];
        this.state.mealsByDay[weekday].push({ recipe, portions, position: intakeIndex });
    }

    remove(weekday: number, intakeIndex: number) {
        const index = this.state.mealsByDay[weekday].findIndex(meal => meal.position === intakeIndex);
        if (index !== -1) {
            this.state.mealsByDay[weekday].splice(index, 1);
        }
    }

    reset() {
        this.state.mealsByDay = this.initialState;
    }
    
    async save() {
        if (_.isEqual(this.state.mealsByDay, this.initialState)) {
            return;
        }
        try {
            await this.gateway.save(this.startDate, this.state.mealsByDay);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.state.error = e;
            } else {
                console.error(e);
                this.state.error = new Error("Unknown error");
            }
        }
    }
}
