import {API} from "@/core/api";
import {EditRecipe} from "@/recipes/edit";
import {WebEditRecipeGateway} from "@/recipes/adapters";
import {Recipe} from "@/recipes/types";

export class Recipes {
    private readonly api: API
    readonly state: {
        loaded: boolean
        recipes: Recipe[]
    }

    constructor(api: API) {
        this.api = api
        this.state = {
            loaded: false,
            recipes: []
        }
    }

    all() {
        this.api
            .get('meals', '', true)
            .then(result => {
                this.state.loaded = true
                if (result.ok) {
                    this.state.recipes = result.value['items']
                }
            });
    }

    recipeByID(id: string): Recipe | undefined {
        return this.state.recipes.find((value: Recipe) => value.id === id)
    }

    async loadByID(id: string) {
        return await this.api.get(`meals/${id}`, null, true)
    }

    deleteItem(item: Recipe) {
        this.api.delete(`meals/${item.id}`).then(result => {
            if (result.ok) {
                const index = this.state.recipes.indexOf(item)
                this.state.recipes.splice(index, 1)
            }
        })
    }

    edit(): EditRecipe {
        const gateway = new WebEditRecipeGateway(this.api)
        return new EditRecipe(gateway)
    }
}
