<script setup lang="ts">
import {formatter} from "@/main";
import ButtonsGroup, {Button} from "@/components/buttons/ButtonsGroup.vue";
import {Product} from "@/products/products";

const props = defineProps<{
  item: Product
}>()
defineEmits<{
  onDelete: [item: Product]
}>()

function makeButtons(): Button[] {
  let buttons = []
  if (props.item.can_edit) {
    buttons.push({id: 'edit', title: 'Edit', style: 'plain'})
  }
  if (props.item.can_delete) {
    buttons.push({id: 'delete', title: 'Delete', style: 'danger'})
  }
  return buttons
}
</script>

<template>
  <tr class="product-row">
    <td>{{ item.name }}</td>
    <td>{{ item.category || '-' }}</td>
    <td class="kcal centered">{{ formatter.format(item.kcal) }}</td>
    <td class="proteins centered">{{ formatter.format(item.proteins) }}</td>
    <td class="carbs centered">{{ formatter.format(item.carbs) }}</td>
    <td class="fats centered">{{ formatter.format(item.fats) }}</td>
    <td class="centered">
      <ButtonsGroup
          v-if="item.can_delete"
          :buttons="makeButtons()"
          @click="(id) => {
            switch (id) {
              case 'edit':
                $router.push(`products/${item.id}/edit`)
                break
              case 'delete':
                $emit('onDelete', item)
                break
            }
          }"
      />
    </td>
  </tr>
</template>

<style scoped lang="sass">
.product-row
  height: 44pt

  *
    vertical-align: middle
</style>