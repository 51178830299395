<script setup>
import {onMounted} from "vue";
import {recipes} from "@/main";
import CenterPageLayout from "@/components/layouts/CenterPageLayout.vue";
import ListActions from "@/components/ListActions.vue";
import DataTable from "@/recipes/components/DataTable.vue";

onMounted(() => {
  recipes.all()
})
</script>

<template>
  <CenterPageLayout>
    <template v-slot:page-body>
      <div class="page-body-content">
        <ListActions
            class="actions"
            search-query=""
            @update:search-query="(value) => { /* do nothing */ }"
            :can-load-prev="false"
            @load-prev="() => { /* do nothing */ }"
            :can-load-next="false"
            @load-next="() => { /* do nothing */ }"
            primary-action="New Recipe"
            @on-primary-action="() => $router.push('recipes/new')"
        />
        <DataTable/>
      </div>
    </template>
  </CenterPageLayout>
</template>

<style scoped lang="sass">
.page-body-content
  padding: 24pt

  .title
    margin-bottom: 16pt

  .actions
    margin-bottom: 24pt
</style>