<script setup lang="ts">
import router from "@/core/routing";
import {products} from "@/main";
import {onMounted, reactive} from "vue";
import ActionsBar from "@/components/ActionsBar.vue";
import {useRoute} from "vue-router";
import {CATEGORIES, emptyProduct, Product} from "@/products/products";
import InputRow from "@/components/InputRow.vue";
import OptionsRow from "@/components/OptionsRow.vue";
import {Result} from "@/core";
import CenterPageLayout from "@/components/layouts/CenterPageLayout.vue";
import LevelsBar from "@/components/LevelsBar.vue";

const route = useRoute()

function getIDFromRoute(): string | undefined {
  return route.params.product_id?.toString()
}

function getExistingIfHasID() {
  const id = getIDFromRoute()
  if (id) {
    return products.state.products.find((value: Product) => value.id === id)
  }
}

const uiState = reactive({
  item: getExistingIfHasID() ?? emptyProduct(),
  error: '',
  isEditing(): boolean {
    return getIDFromRoute() !== undefined
  },
  fetch() {
    const productID = getIDFromRoute()
    if (!productID || this.item && this.item.id === productID) {
      return
    }
    products.loadByID(productID).then((result: Result<any>) => {
      if (result.ok) {
        this.error = ''
        this.item = result.value
      } else {
        this.error = result.error.toString()
      }
    })
  },
  save() {
    if (getIDFromRoute() != null) {
      this.withResultHandler(products.update(this.item))
    } else {
      this.withResultHandler(products.add(this.item))
    }
  },
  withResultHandler(promise: Promise<any>) {
    promise.then(result => {
      if (result.status === 'success') {
        this.error = ''
        this.back()
      } else {
        this.error = result.reason
      }
    })
  },
  cancel() {
    this.error = ''
    this.back()
  },
  back() {
    router.back()
  }
})

onMounted(() => {
  uiState.fetch()
})
</script>

<template>
  <CenterPageLayout>
    <template v-slot:page-body>
      <div class="page-body-content">
        <LevelsBar>
          <template v-slot:left>
            <h1 class="title is-3">{{ uiState.isEditing() ? 'Edit Product' : 'New Product' }}</h1>
          </template>
          <template v-slot:right>
            <ActionsBar @on-save="() => uiState.save()" @on-cancel="() => uiState.cancel()"/>
          </template>
        </LevelsBar>
        <p v-if="uiState.error.length > 0" id="error">{{ uiState.error }}</p>
        <form>
          <InputRow label="Name" v-model:value="uiState.item.name"/>
          <InputRow label="Kcal" v-model:value="uiState.item.kcal"/>
          <OptionsRow
              label="Category"
              v-model:value="uiState.item.category"
              :options="CATEGORIES.map((value) => value.toLowerCase())"
          />
          <InputRow label="Proteins" v-model:value="uiState.item.proteins"/>
          <InputRow label="Carbs" v-model:value="uiState.item.carbs"/>
          <InputRow label="Fats" v-model:value="uiState.item.fats"/>
        </form>
      </div>
    </template>
  </CenterPageLayout>
</template>

<style scoped lang="sass">
.page-body-content
  padding: 24pt
</style>