import {API} from "@/core/api";
import {Product} from "@/products/products";
import {EditRecipeGateway, NewRecipe, NewRecipeIngredient} from "@/recipes/edit";
import {Result} from "@/core";
import {Recipe} from "@/recipes/types";

export class WebEditRecipeGateway implements EditRecipeGateway {
    private readonly api: API

    constructor(api: API) {
        this.api = api
    }

    fetchProducts(size: number, query?: string): Promise<Result<Product[]>> {
        let params = `size=${size}`
        if (query) {
            params += `&q=${query}`
        }
        return this.api
            .get('products', params, true)
            .then(result => {
                if (result.ok) {
                    return { ok: true, value: result.value['items'] }
                }
                return result
            })
    }

    create(newRecipe: NewRecipe): Promise<Result<Recipe>> {
        const body: any = newRecipe
        body.ingredients = newRecipe.ingredients!.map((ingredient: NewRecipeIngredient) => {
            return { product_id: ingredient.product.id, amount: ingredient.amount }
        })
        return this.api.post('meals', body)
    }

    update(newRecipe: NewRecipe): Promise<Result<Recipe>> {
        const body: any = newRecipe
        body.ingredient_changes = newRecipe.ingredients!
            .filter((value) => value.action)
            .map((ingredient: NewRecipeIngredient) => {
                return {
                    product_id: ingredient.product.id,
                    amount: ingredient.amount,
                    action: ingredient.action
                }
            })
        delete body.ingredients
        return this.api.put(`meals/${newRecipe.id}`, body)
    }
}