import { API } from "@/core/api";
import { emptyRecipe, Recipe } from "@/recipes/types";
import { WebEditPlanGateway } from "./adapters";
import { EditPlan, PlannedMealsByDay } from "./edit-plan";
import { editPlan } from "./factories";
import { makeStartDate } from "./misc";

export type Intake = {
    id?: string
    title: string
    time: string
    recipe: Recipe
    portions?: number
}

function emptyIntakes(): Intake[] {
    return [
        { title: 'Breakfast', time: '09:00', recipe: emptyRecipe() },
        { title: 'First Snack', time: '11:00', recipe: emptyRecipe() },
        { title: 'Lunch', time: '13:00', recipe: emptyRecipe() },
        { title: 'Second Snack', time: '16:00', recipe: emptyRecipe() },
        { title: 'Dinner', time: '19:00', recipe: emptyRecipe() }
    ]
}

export class CurrentPlan {
    readonly days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]

    private readonly api: API
    readonly day: number
    readonly state: {
        loaded: {
            intakes: boolean
            recipes: boolean
        }
        intakes: Intake[][]
        recipes: Recipe[]
        shoppingList: object[]
    }

    constructor(api: API) {
        this.api = api
        this.day = (new Date()).getDay()
        this.state = {
            loaded: {
                intakes: false,
                recipes: false
            },
            intakes: Array.from(
                { length: 7 },
                () => emptyIntakes()
            ),
            recipes: [],
            shoppingList: []
        };
    }

    async all() {
        if (this.state.loaded.intakes) {
            return new Promise((resolve) => { resolve({}) });
        }
        const result = await this.api.get(
            `plan`,
            new URLSearchParams({ start_date: makeStartDate().toISOString().split("T")[0] }),
            true
        );
        if (!result.ok) {
            return;
        }
        for (const [weekday, intakes] of Object.entries(result.value)) {
            for (const [index, intake] of (intakes as any[]).entries()) {
                this.state.intakes[parseInt(weekday) - 1][index].recipe = intake.recipe;
            }
        }
    }

    async shoppingList() {
        const result = await this.api.get(
            'intakes/shopping-list', 
            new URLSearchParams({ start_date: makeStartDate().toISOString().split("T")[0] })
        )
        if (result.ok) {
            this.state.shoppingList = result.value['products']
        }
        return result
    }

    edit(): EditPlan {
        const mealsByDay: PlannedMealsByDay = {};
        for (const [index, intakes] of this.state.intakes.entries()) {
            mealsByDay[index + 1] = intakes.map(intake => ({
                recipe: intake.recipe,
                portions: intake.portions ?? 1,
                position: index
            }));
        }
        return editPlan(makeStartDate(), mealsByDay, this.api);
    }
}
