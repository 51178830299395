<script setup lang="ts">

import DefaultButton from "@/components/buttons/DefaultButton.vue";

const props = defineProps(['canLoadPrev', 'canLoadNext'])
const emit = defineEmits(['loadPrev', 'loadNext'])

function onPrev() {
  if (props.canLoadPrev) {
    emit('loadPrev')
  }
}

function onNext() {
  if (props.canLoadNext) {
    emit('loadNext')
  }
}
</script>

<template>
  <div class="page-controls">
    <DefaultButton
        class="plain-button"
        title="Prev"
        :disabled="!canLoadPrev"
        @click="onPrev"
    />
    <DefaultButton
        class="plain-button"
        title="Next"
        :disabled="!canLoadNext"
        @click="onNext"
    />
  </div>
</template>

<style scoped lang="sass">
.page-controls
  display: flex
  column-gap: 4pt
</style>