import { API } from "@/core/api";
import {EditPlanGateway, PlannedMealsByDay} from "@/plan/edit-plan";
import { Recipe } from "@/recipes/types";

export class WebEditPlanGateway implements EditPlanGateway {
    private api: API;

    constructor(api: API) {
        this.api = api;
    }

    async load(startDate: Date): Promise<PlannedMealsByDay> {
        const result = await this.api.get(
            "plan",
            new URLSearchParams({ start_date: startDate.toISOString().split("T")[0] })
        );
        if (!result.ok) {
            throw result.error;
        }
        return result.value;
    }
    
    async searchRecipes(query: string): Promise<Recipe[]> {
        const result = await this.api.get("meals", new URLSearchParams({ query }));
        if (!result.ok) {
            return [];
        }
        return result.value.items;
    }
    
    async save(startDate:Date, mealsByDay: PlannedMealsByDay): Promise<void> {
        const items: any = {};
        for (const day in mealsByDay) {
            items[day] = mealsByDay[day].map((entry) => ({
                recipe_id: entry.recipe.id,
                portions: entry.portions,
                position: entry.position
            }));
        }
        console.log(items);
        await this.api.post("plan", { start_date: startDate.toISOString().split("T")[0], items: items });
    }
}
